import axios from 'axios'
import swal from "sweetalert";
import {getCookie} from "@/utils/cookie";
import {getConfigInfo} from "@/utils/config";
//封装路由请求
const request = axios.create({
  baseURL:getConfigInfo().BASE_URL,
  timeout: 5000
})
// 请求拦截器
request.interceptors.request.use(config => {
  //如果cookie内的值不为空，那么便将cookie内值设置到表头
  if (getCookie("Authorization") !== null) {
    config.headers['Authorization'] = getCookie("Authorization");
  }
  return config
}, error => {
  return Promise.reject(error)
})

request.interceptors.response.use(response => {
  if(response.data.code!=0){
    swal({
      text: response.data.message,
    });
  }
  return response.data
}, error => {
  return Promise.reject(error)
})

export default request


